<template>

  <div class="content">
    <sub-title>이벤트</sub-title>
    <div class="notice">
      <div class="board_list">
        <div class="event_detail" v-if="item.eventImg != null">
          <img  alt="" :src="item.eventImg">
        </div>
        <div class="event_detail" v-if="item.content != null" v-html="item.content">

        </div>
        <div class="go_back" style="display: flex;align-items: center;justify-content: center">
          <button @click="goback()" style="background-color: #ff0000;border-radius: 2px">리스트 보기</button>
        </div>

      </div>

    </div>
    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>



</template>

<script>
import {postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
import {getBoardDetail} from "@/network/userRequest";
  export default {
    name: "EventDetail",
    mixins:[postionMixin],
    components: {
      MyUncheckedMessageComp, SubTitle,
    },
    data() {
      return {
        item: {id: 0, eventImg: '', commentList: [], commentable: 1},
        boardId: 0,
        sportsConst,
        position:"이벤트",
      }
    },
    methods: {
      boardDetail() {
        this.$store.commit(RECEIVE_SHOW_LOADING)
        getBoardDetail(this.boardId).then(res => {
          this.item = {}
          if (res.data.success) {
            this.item = res.data.data
          }
          this.$store.commit(RECEIVE_HIDE_LOADING)
        })
      },
      commentSuccess() {
        this.boardDetail()
      },
      goback() {
        this.$router.go(-1)
      },
    },
    created() {
      console.log(12121212)
      this.boardId = this.$route.params.id
      console.log(this.boardId)
      this.boardDetail()
    }
  }
</script>

<style scoped>

  .board_list{
    box-sizing: border-box;
    padding: 1rem 0;
  }
  .board_list .event_detail {
    box-sizing: border-box;
    max-width: 70rem;
    margin: 0 auto;
  }
  .event_detail img{
    width: 100%;
  }
  .go_back button {
    width: 12rem;
    font-size: 1.2rem !important;
    margin: 1rem 0;
    padding: 1rem;
  }
  @media screen and (max-width: 1024px) {
    .board_list{
      box-sizing: border-box;
      padding: 1rem!important;
    }
  }
</style>